
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  ConceptAvailability,
  ProuductsAvailability,
  RestaurantFeedFilters,
  RestaurantFeedFiltersType,
} from '@/interfaces';
import { ConceptsService } from '@/services';
import { toLocalDate, toLocale, toLocaleTime } from '@/helpers/date';
import { getLocale } from '@/helpers/user';
import { ConceptModel } from '@/models';

import RestaurantFeedFilter from '@/components/filters/RestaurantFeed.vue';
import TimeIcon from '@/components/TimeIcon.vue';

interface Item {
  location: string;
  locationId: number;
  concept: string;
  conceptId: number;
  text: string;
  time: string;
  id: number;
  status: boolean;
  createdAt: string;
  country: string;
  type: RestaurantFeedFiltersType;
  until?: string;
}

const conceptsStore = namespace('concepts');

@Component({ components: { RestaurantFeedFilter, TimeIcon } })
export default class extends Vue {
  filters: RestaurantFeedFilters = {};

  items: Item[] = [];

  @conceptsStore.State('conceptsForUser')
  allConcepts!: ConceptModel[];

  get filteredItems(): Item[] {
    let items = this.items;

    if (this.filters.countries?.length) {
      items = items.filter((item) => this.filters.countries?.includes(item.country));
    }

    if (this.filters.locationId) {
      items = items.filter((item) => this.filters.locationId === item.locationId);
    }

    if (this.filters.conceptsIds?.length) {
      items = items.filter((item) => this.filters.conceptsIds?.includes(item.conceptId));
    }

    if (this.filters.type?.length) {
      items = items.filter((item) => this.filters.type?.includes(item.type));
    }

    return items;
  }

  @Watch('filters', { deep: true })
  async onFiltersChange(): Promise<void> {
    await this.fetchItemsWithDate({ date: this.filters.date });
  }

  async mounted(): Promise<void> {
    await this.fetchItemsWithDate({});
  }

  async fetchItemsWithDate({
    date,
    conceptId,
  }: {
    date?: string;
    conceptId?: string;
  }): Promise<void> {
    // Call service methods again with the updated date
    this.items = [
      ...(await ConceptsService.getConceptsAvailability(date, conceptId)),
      ...(await ConceptsService.getUnavailableProducts(date, conceptId)),
    ]
      .map(this.addDetails)
      .filter((item) => item.id)
      .sort((a: Item, b: Item) => (a.createdAt > b.createdAt ? -1 : 1));
  }

  dateChanged(ndx: number): boolean {
    return Boolean(
      ndx <= 0 ||
        this.filteredItems[ndx].createdAt.substr(0, 10) !==
          this.filteredItems[ndx - 1].createdAt.substr(0, 10)
    );
  }

  toLocalDate(dateString: string): string {
    return toLocalDate(dateString, getLocale());
  }

  formattedUntil(until: string): string {
    const isDate = (date: string): boolean => {
    const parsedDate = new Date(date);
      return parsedDate.toString() !== "Invalid Date" && !isNaN(parsedDate.getTime());
    };
    if (isDate(until)) {
        return new Date(until).toLocaleString();
    } else {
        return this.$t(`restaurants.turningOffDurations.endOfDay`).toString();
    }
  }


  private addDetails(item: ProuductsAvailability | ConceptAvailability): Item {
    const concept = this.allConcepts.find((_item) => item.conceptId === _item.id);

    if (!concept) {
      return {} as Item;
    }

    return {
      ...item,
      location: concept.location?.name || '',
      locationId: concept.locationId,
      country: concept.location?.country || '',
      concept: concept.name,
      text: (item as ProuductsAvailability)?.item?.name || (item as ConceptAvailability).provider,
      time: toLocaleTime(item.createdAt),
      type: (item as ProuductsAvailability).item
        ? RestaurantFeedFiltersType.product
        : RestaurantFeedFiltersType.restaurant,
      until: item.until || '',
    };
  }

  updateFilters(filters: RestaurantFeedFilters): void {
    this.filters = filters;
  }
}
