
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { getLocationsFromConceptList, getConceptsForLocation } from '@/helpers/concepts';
import { enumToArray } from '@/helpers/enum';
import { RestaurantFeedFilters, RestaurantFeedFiltersType, TextValuePair } from '@/interfaces';
import { ConceptModel } from '@/models';

import CountriesFilter from './Countries.vue';

const conceptsStore = namespace('concepts');

@Component({ components: { CountriesFilter } })
export default class RestaurantFeedFilter extends Vue {
  @Prop({ default: () => ({}) }) readonly initFilters?: RestaurantFeedFilters;

  filters: RestaurantFeedFilters = {};
  menu = false;

  @conceptsStore.State('conceptsForUser')
  readonly userConcepts!: ConceptModel[];

  get locations(): TextValuePair[] {
    return getLocationsFromConceptList(this.userConcepts);
  }

  get concepts(): TextValuePair[] {
    return getConceptsForLocation(this.userConcepts, this.filters.locationId as number);
  }

  get types(): string[] {
    return enumToArray(RestaurantFeedFiltersType);
  }

  @Watch('initFilters')
  onInitFiltersChange(): void {
    this.filters = {
      type: this.initFilters?.type || [],
      locationId: this.initFilters?.locationId || null,
      conceptsIds: this.initFilters?.conceptsIds || [],
      countries: this.initFilters?.countries || [],
      date: this.initFilters?.date,
    };
  }

  update(): void {
    this.$emit('updated', this.filters);
  }

  updateCountries(countries: string[]): void {
    this.filters.countries = countries;
    this.update();
  }

  updateDate(date: string): void {
    this.filters.date = date;
    this.update();
  }
}
